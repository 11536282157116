<template>
  <div class="h-full px-2">
    <Loading the_class="text-light" v-show="loading"></Loading>
    <div class="flex -mx-2" v-show="!loading">
      <section class="flex flex-wrap flex-col lg:flex-row items-start items-center lg:justify-center w-full lg:px-10">
        <vx-card class="relative fx-width mx-4 bg-white w-full mb-10 pt-10 text-center text-primary-dark rounded-lg"
          v-for="plan in plans" :key="plan.id">
          <h5 class="font-bold text-lg text-primary pb-8">{{plan.title}}</h5>
          <img class="pb-4 inline h-48" :src="plan.image"/>
          <ul class="text-sm font-bold mb-10">
            <li class="pt-4 pb-4 border-b border-gray-200" v-for="ad in plan.advantages">
              {{ ad }}
            </li>
          </ul>

          <div class="SpecialClass w-full vx-row bg-primary absolute rounded-bl-2xl rounded-br-2xl inset-x-0 bottom-0 -mb-4">
            <div class="w-2/5 rounded-bl-2xl bg-warning h-14 rounded-tr-3xl">
              <h5
                style="padding-top:13px"
                class=" kh-e text-white font-bold ml-auto mr-auto text-center text-lg align-bottom"
              >
                {{ plan.price }} <span>*taxes not included</span>
              </h5>
            </div>
            <div class="w-3/5 bg-primary h-14 rounded-br-2xl">
              <LoadingInBox v-if="pay_loading"></LoadingInBox>
              <div style="display: flex; align-items: center; height: 100%"    @click="pay()">
<!--                <h5-->
<!--                  style="padding-top:13px; cursor: pointer"-->
<!--                  class="text-white ml-auto mr-auto font-bold text-center text-lg align-bottom"-->
<!--                  @click="pay(plan.price)"-->
<!--                >-->
<!--                  Paypal-->
<!--                  <i class="fab fa-paypal"></i>-->
<!--                </h5>-->
                <h5
                  style="padding-top:13px; cursor: pointer"
                  class="text-white ml-auto mr-auto font-bold text-center text-lg align-bottom"
                >
                  Store
                  <i class="fas fa-coins"></i>
                </h5>
              </div>
            </div>
          </div>
        </vx-card>
      </section>
    </div>
  </div>
</template>
<script>
import Loading from "../components/Loading";
import LoadingInBox from "../components/LoadingInBox";
export default {
  components: {LoadingInBox, Loading},
  data() {
    return {
      user_data: {},
      loading: true,
      plans: null,
      pay_loading: false,
    };
  },

  methods: {
    pay(price) {
      if(price) {
        this.pay_loading = true;
        let get_cookies = $cookies.get('wro_user_data');
        this.$API.payment.paypal_payment({
          user_id: this.user_data.user_id,
          orginzer_id: this.user_data.orginzer_id,
          amount: price,
          currency: 'USD',
        })
          .then(response => {
            this.pay_loading = false;
            window.open(response.data,'_blank');

          }).catch(() => { this.pay_loading = false});
      } else {
        window.open('https://store.thaka.sa/shop/product/wro-v-team-year-2021-team-events-subscription-fee-477','_blank');
      }
    }
  },

  mounted() {
    this.$API.plans.getPlans()
    .then(response => {
      this.plans = response
      this.loading = false;
    })

    if(this.$route.query.user_id && this.$route.query.orginzer_id) {
      this.user_data = {user_id: this.$route.query.user_id, orginzer_id: this.$route.query.orginzer_id}
    } else {
      this.$router.push('/');
    }
  }
}
</script>
<style scoped lang="scss">

.the_loading {
  min-height: 100%;
}

.kh-e h5{
  cursor: pointer;
}

 h5 span{
  font-size: 11px!important;
  display: block;
  text-transform: uppercase;
  font-weight: bold;

}

.SpecialClass {
  margin: 0 -1.5rem !important;
}

.fx-width{
  max-width: 500px!important;
  margin: auto;
}

</style>
